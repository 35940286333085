<template xmlns:v-slot="http://www.w3.org/1999/XSL/Transform">
  <div style="background: #ecf0f5">
    <v-container fluid>
      <v-row style="margin-top: -20px" dense>
        <v-col>
          <v-breadcrumbs :items="items" light>
            <template v-slot:divider>
              <v-icon small>$vuetify.icons.values.mdiChevronRight</v-icon>
            </template>
          </v-breadcrumbs>
          <v-divider></v-divider>
        </v-col>
      </v-row>
      <v-row>
        <v-col>
          <span><v-icon large class="mr-3">$vuetify.icons.values.mdiBookMultiple</v-icon></span>
          <span>{{$t('msg.learning_material_page_heading_name')}}</span>
        </v-col>
      </v-row>
    </v-container>
    <v-overlay :value="overlay">
      <v-dialog
        v-model="overlay"
        hide-overlay
        persistent
        width="300"
      >
        <v-card
          color="black"
          dark
        >
          <v-card-text>
            {{$t('msg.loading')}}
            <v-progress-linear
              indeterminate
              color="white"
              class="mb-0"
            />
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-overlay>
    <v-container fluid style="padding-bottom: 70px">
      <v-card>
        <v-app-bar
          dark
          color="#8C92DC">
          <v-toolbar-title>{{$t('msg.table_heading_group')}}</v-toolbar-title>
        </v-app-bar>
        <v-row>
          <v-col cols="12" md="12" lg="12" class="text-center">
            <v-data-table :headers="headers" :disable-sort="true"
                          :footer-props="{
                        'items-per-page-options': pagination.itemPerPageOptions,
                        'show-current-page':true
                        }"
                          :items="itemsWithIndex">
              <template v-slot:item.download="{ item }">
                    <span v-if="item.fileAvailable">
                      <v-icon style="cursor: pointer" color="green" v-bind:key="item.id"
                              @click.native="downloadFile(item)">
                          $vuetify.icons.values.mdiFileDownloadOutline
                        </v-icon>
                    </span>
                    <span v-else>
                      <v-icon>mdi-file-download</v-icon>
                    </span>
              </template>
            </v-data-table>
          </v-col>
        </v-row>
      </v-card>
    </v-container>
    <v-snackbar v-model="reportDownloadError" :bottom="true" dark color="#8C92DC" :right="true" :timeout="6000"
                :vertical="true">
      <span v-html="$t('msg.report_unavailable')"></span>
      <v-btn dark text @click="reportDownloadError = false" v-html="$t('msg.close_label')"></v-btn>
    </v-snackbar>
  </div>
</template>

<script>
import { dashboardService, downloadFileService } from '../_services'
import fileSaver from 'file-saver'

export default {
  name: 'LearningResources',
  data () {
    return {
      overlay: false,
      sampleData: '',
      tableData: [],
      loading: true,
      pagination: {
        itemsPerPage: 5,
        itemPerPageOptions: [5]
      },
      reportDownloadError: false,
      headers: [
        {
          text: this.$t('msg.sl_no'),
          align: 'center',
          sortable: false,
          value: 'index',
          selectedCheckbox: true,
          toggle: false
        },
        {
          text: this.$t('msg.resource_name'),
          align: 'center',
          sortable: false,
          value: 'resourceName',
          selectedCheckbox: true,
          toggle: false
        },
        {
          text: this.$t('msg.download'),
          align: 'center',
          sortable: false,
          value: 'download',
          selectedCheckbox: true,
          toggle: false
        }
      ],
      items: [
        {
          text: 'Home',
          disabled: true,
          href: 'breadcrumbs_dashboar'
        },
        {
          text: 'Group Results',
          disabled: true,
          href: 'breadcrumbs_link_1'
        }
      ]
    }
  },
  methods: {
    getPageData: function () {
      this.overlay = true
      dashboardService.getLearningResources().then(response => {
        this.overlay = false
        if (response.dataResponse.learningResourcesData != null) {
          this.tableData = response.dataResponse.learningResourcesData
        }
        this.loading = false
      })
    },
    downloadFile: function (item) {
      downloadFileService.downloadResource({ id: item.id }).then(response => {
        if (response) {
          fileSaver.saveAs(response, item.resourceName + item.extension)
        }
      })
    }
  },
  computed: {
    itemsWithIndex: function () {
      return this.tableData.map(
        (item, index) => ({
          ...item,
          index: index + 1
        }))
    }
  },
  mounted () {
    this.$store.dispatch('home/setCurrentPage', 'learningResources')
    this.$cookies.set('standard_current_page', 'learningResources')
    this.getPageData()
  }
}
</script>

<style scoped>
.v-breadcrumbs {
  padding: 7px 0px 0px 0px !important;
}
</style>
<style>
.v-input input{
  cursor: pointer !important;
}
.v-input__slot{
  cursor: default !important;
}
.removeFilterBottomMargin{
  margin-bottom: -20px;
}
.dashboardFooterBottomMargin{
  margin-bottom: 100px;
}
</style>
